import React from "react";
import { Vacations } from "./vacations.styles";
import { ReactComponent as DollarIcon } from "../../assets/icons/vacations/dollar-sign.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/vacations/clock.svg";
import { Wrap } from "../wrap/wrap.component";
export type VacationItem = {
  title: string;
  description: string;
  variant: "full_time" | "part_time";
  salary: {
    from: number;
    to: number;
  };
};

export type VacationSection = {
  title: string;
  vacations: VacationItem[];
};

export type VacationsComponentProps = {
  sections: VacationSection[];
};

export const VacationsComponent: React.FC<VacationsComponentProps> = (props) => {
  const { sections } = props;
  return (
    <Vacations.Body>
      {sections.map((section, idx) => (
        <Vacations.ContainerSection key={`vacation-section-${idx}`}>
          <Vacations.SectionTitle>{section.title}</Vacations.SectionTitle>
          <Vacations.Items.Container>
            {section.vacations.map((vacation, idx) => {
              const { Item: Vacation } = Vacations.Items;
              return (
                <Vacation.Container key={`vacation-item-${idx}`}>
                  <Vacation.Title>{vacation.title}</Vacation.Title>
                  <Vacation.Description dangerouslySetInnerHTML={{ __html: vacation.description }} />
                  <Vacation.Footer.Data>
                    <Vacation.Footer.Item>
                      <Wrap sx={{ display: "flex", alignItems: "center", marginRight: "1rem" }}>
                        <ClockIcon /> <span style={{ marginLeft: "0.5rem" }}>{vacation?.variant || "N/A"}</span>
                      </Wrap>
                    </Vacation.Footer.Item>
                    <Vacation.Footer.Item>
                      <Wrap sx={{ display: "flex", alignItems: "center", marginRight: "0.5rem" }}>
                        <DollarIcon />
                      </Wrap>
                      {vacation.salary.to || "N/A"}
                    </Vacation.Footer.Item>
                  </Vacation.Footer.Data>
                </Vacation.Container>
              );
            })}
          </Vacations.Items.Container>
        </Vacations.ContainerSection>
      ))}
    </Vacations.Body>
  );
};
