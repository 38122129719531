import React, { ChangeEventHandler, useEffect, useState } from "react";
import MainBg from "../../assets/backgrounds/contact/contact_main_bg.png";
import { ReactComponent as ContactIcon } from "../../assets/icons/contact/question_icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/contact/searchIcon.svg";
import { SlideSection } from "../../components/silde-section/slide-section.component";
import { Section } from "../../components/section/section.component";
import { Wrap } from "../../components/wrap/wrap.component";
import { ContactForm, ContactUsMainDiv } from "./contact.styles";
import { Input } from "../../components/inputs/input/input.component";
import { ButtonArrow } from "../../components/button-arrow/button-arrow.component";
import MaoMarkers from "../../assets/backgrounds/contact/MapImage.svg";
import { uiDataWebsiteText, uiDataGlobalOffices, uiDataSelectedLanguageId, uiDataGlobalOfficeDropDown } from "../../redux/uiData/selectors";
import { useDispatch, useSelector } from "../../redux/store";
import operations from "../../redux/uiData/operations";
import * as yup from "yup";
import { parseYupError } from "../../utils/parseYupError";

import parse from "html-react-parser";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";
import { Base64 } from "js-base64";
import { Loader } from "../../components/loader/loader.component";
import { FindCountry } from "../bank-accounts/bank-accounts.styles";
import { ReactComponent as CheckMark } from "../../assets/icons/tick_light.svg";
import { ReactComponent as CrossMark } from "../../assets/icons/close_icon.svg";
import { fetchGlobalOffices } from "../../api/services/fetch.ui";
import { useMediaQuery } from "../../utils/use-media-query";

export const ContactPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("sm");
  const { contactBlackBanx } = useSelector(uiDataWebsiteText);
  const language = useSelector(uiDataSelectedLanguageId);
  const countryy = useSelector(uiDataGlobalOfficeDropDown);

  const [location, setLocation] = useState("");
  const [country, setCountry] = useState("");
  const [results, setResults] = useState([]);
  const [response, setResponse] = useState("");
  const [countryResponse, setCountryResponse] = useState("");
  const [formData, setFormData] = useState<{ name: string; email: string; message: string }>({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  //-----------------
  const [errors, setErrors] = useState<{
    name: string | null;
    email: string | null;
    message: string | null;
  }>({ name: null, email: null, message: null });
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleCountry = (value: string) => {
    setCountry(value);
  };

  const handleCountryClick = (code: string) => {
    setCountry("");
    if (code !== location) {
      setCountryResponse('');
      setResults([]);
      setLocation(code)
      setCountryLoading(true);
    }
  };
  const handleSubmit = () => {
    setResponse("");
    const schema = yup.object().shape({
      name: yup
        .string()
        .min(2, { name: contactBlackBanx.cus_qstn_nme_error })
        .required({ name: contactBlackBanx.cus_qstn_nme_error }),
      email: yup
        .string()
        .email({ email: contactBlackBanx.cus_qstn_eml_error })
        .required({ email: contactBlackBanx.cus_qstn_eml_error }),
      message: yup.string().required({ message: contactBlackBanx.cus_qstn_msg_error }),
    });

    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({ name: null, email: null, message: null });
      })
      .then(() => {
        setLoading(true);
      })
      .then(() =>
        dispatch(
          operations.postSendCompanyMessage({
            languageId: language,
            destination: formData.email,
            subject: formData.name,
            mailContent: formData.message,
          }),
        ),
      )
      .then(({ payload }) => {
        setResponse(Base64.decode((payload as { status_msg: string }).status_msg));
        setFormData({
          name: "",
          email: "",
          message: "",
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const errors = parseYupError(err);
        setErrors(errors as { name: string; email: string; message: string });
      });
    //----------
  };

  const renderResults = () => {
    return (
      <>
        <Wrap
          sx={{
            width: "100%",
            marginBottom: "1rem",
            position: "absolute",
            top: "5rem",
            zIndex: 20,
          }}
        >
          <FindCountry.Result>
            {countryy
              .map((item: any, index: number) => {
                return { ...item, index };
              })
              .filter((item: any, index: any) => item.name?.toLowerCase().includes(country?.toLowerCase()))
              .map((item: any, index: any, arr: any) => {
                return (
                  <>
                    <Wrap
                      onClick={() => {
                        handleCountryClick(item.code);
                      }}
                      sx={{
                        cursor: "pointer",
                        ...(index !== arr.length - 1
                          ? {
                            borderBottom: "1px solid white",
                            marginBottom: "1.5rem",
                            paddingBottom: "10px",
                            cursor: "pointer",
                          }
                          : {}),
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{item.name} </div>
                      {!item.isRegisterBlock ? <CheckMark width={"24px"} /> : <CrossMark width={"24px"} />}
                      {/* <br /> */}
                    </Wrap>
                  </>
                );
              })}
          </FindCountry.Result>
        </Wrap>
      </>
    );
  };
  useEffect(() => {
    if (location) {
      getGlobalOffices();
    }
  }, [location, language])

  async function getGlobalOffices() {
    const { data } = await fetchGlobalOffices({ languageId: language, countryCode: location });
    //@ts-ignore
    if (data.status_msg) {
      //@ts-ignore
      setCountryResponse(Base64.decode((data as { status_msg: string }).status_msg));
    }
    else {
      const companies = data?.data.map((company) => {
        const sequence = ['company_name,info', 'street_address', 'city,zip', 'country']
        const companyDetails: string[] = [];
        //@ts-ignore
        sequence.forEach((title) => {
          const titles = title.split(',')
          const smallTitles: string[] = [];
          titles.forEach((key) => {
            //@ts-ignore
            const value = company[key];
            if (value?.trim()) {
              smallTitles.push(Base64.decode(value))
            }
          })
          companyDetails.push(smallTitles.join(', '))
        })
        return companyDetails;
      })
      setResults(companies)
    }
    setCountryLoading(false);
  }

  return (
    <>
      <HelmetContainer metaData={contactBlackBanx.metaData} />
      <SlideSection
        title={parse(contactBlackBanx.cus_headone + " <br> " + contactBlackBanx.cus_cntctus)}
        bgImage={MainBg}
        titleAlign={"center"}
        titleWidth={"100%"}
        justify={"center"}
        bgVariant={"gradient"}
        BgImageStyles={{
          backgroundSize: "cover",
        }}
      />
      <Section mainContent m={"auto auto 16rem"}>
        <ContactForm.Root>
          <ContactUsMainDiv>
          {isMobile &&
        <Wrap
        sx={{
          position:"absolute",
          display: "flex",
          width: "25%",
          justifyContent: "flex-end",
          alignItems:"flex-start",
          alignSelf:"flex-end",
          opacity:0.2,
          top:-90
        }}
      >
        <ContactIcon />
      </Wrap>
        }  
            <Wrap sx={{ display: "flex", flexDirection: isMobile ?"column":"initial",  marginBottom: "4rem", width: "90%", marginRight: "auto" }}>
              <Wrap sx={{ maxWidth: "30rem", marginRight: "auto", marginBottom:isMobile ?"40px":"0px" }}>
                <ContactForm.Support.Title>{contactBlackBanx.cus_sup_inq}</ContactForm.Support.Title>
                <Wrap sx={{ marginTop: "1rem", marginBottom: "2rem" }}>
                  <ContactForm.Support.Text href={"mailto:support@blackbanx.com"}>
                    {contactBlackBanx.cus_sup_inq_email}
                  </ContactForm.Support.Text>
                </Wrap>
                <Wrap>{contactBlackBanx.cus_sup_chat}</Wrap>
                {contactBlackBanx.cus_sup_chat_sub}
              </Wrap>
              <Wrap sx={{ marginLeft: isMobile?"0rem":"3rem" }}>
                <ContactForm.Support.Title>{contactBlackBanx.cus_con_prir_inq}</ContactForm.Support.Title>
                <Wrap sx={{ marginTop: "1rem", marginBottom: "2rem" }}>
                  <ContactForm.Support.Text href={`mailto:${contactBlackBanx.cus_sup_prir_email}`}>
                    {contactBlackBanx.cus_sup_prir_email}
                  </ContactForm.Support.Text>
                </Wrap>
                <ContactForm.Support.Text href={`mailto:${contactBlackBanx.cus_sup_prir_email_nxt}`}>
                  {contactBlackBanx.cus_sup_prir_email_nxt}
                </ContactForm.Support.Text>
              </Wrap>
            </Wrap>
            <Wrap sx={{ alignSelf: "flex-start" }}>
              <ContactForm.Support.Title>{contactBlackBanx.cus_sup_chat?.chatSection?.title}</ContactForm.Support.Title>
              <ContactForm.Support.Title>
                {contactBlackBanx.cus_sup_chat?.chatSection?.subtitle}
              </ContactForm.Support.Title>
              <ContactForm.Support.Title>{contactBlackBanx.cus_leave_shortmsg}</ContactForm.Support.Title>
            </Wrap>
            <Wrap sx={{ marginBottom: "1rem", width: "100%" }}>
              <Input
                value={formData.name}
                onChange={handleInputChange}
                label={contactBlackBanx.cus_qstn_nme}
                name={"name"}
                placeholder={contactBlackBanx.cus_qstn_nme_txtbx}
                fullWidth
                error={Boolean(errors.name)}
                helperText={errors.name as string}
              />
            </Wrap>
            <Wrap sx={{ marginBottom: "1rem", width: "100%" }}>
              <Input
                value={formData.email}
                onChange={handleInputChange}
                label={contactBlackBanx.cus_qstn_eml}
                name={"email"}
                placeholder={contactBlackBanx.cus_qstn_eml_txtbx}
                fullWidth
                error={Boolean(errors.email)}
                helperText={errors.email as string}
              />
            </Wrap>
            <Wrap sx={{ marginBottom: isMobile? "2rem":"5rem", width: "100%" }}>
              <Input
                value={formData.message}
                onChange={handleInputChange}
                name={"message"}
                placeholder={contactBlackBanx.cus_qstn_msg_txtbx}
                fullWidth
                error={Boolean(errors.message)}
                helperText={errors.message as string}
              />
            </Wrap>

            {response ?? <ContactForm.Support.Title>{response}</ContactForm.Support.Title>}
            <Wrap sx={{ alignSelf: "end", zIndex: "2" }}>
              {!loading ? <ButtonArrow onClick={handleSubmit}>{contactBlackBanx.cus_qstn_sntbtn}</ButtonArrow> : <Loader />}
            </Wrap>
          </ContactUsMainDiv>
        {!isMobile &&
        <Wrap
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ContactIcon />
      </Wrap>
        }  
        </ContactForm.Root>
        {/*  */}
        <Wrap sx={{ width: isMobile?"100%":"55%" }}>
          <Wrap sx={{ width: "100%", marginTop: "10rem", marginBottom: "3rem" }}>
            <ContactForm.Support.Title>{contactBlackBanx.cus_branch_hd}</ContactForm.Support.Title>
          </Wrap>
          <Wrap sx={{ marginBottom: "2rem", width: "100%", position: 'relative' }}>
            <Input
              value={country}
              onChange={(e) => handleCountry(e.target.value)}
              // label={contactBlackBanx.cus_branch_subhd}
              name={"Location"}
              placeholder={contactBlackBanx.cus_branch_searchtext}
              fullWidth
              // error={Boolean(errors.message)}
              // helperText={errors.message as string}
              endIcon={<SearchIcon />}
            />

            {country && renderResults()}
          </Wrap>

          {location && (
            <>
              <Wrap sx={{ width: "100%", marginBottom: "1rem" }}>
                <ContactForm.Support.Title>Results</ContactForm.Support.Title>
              </Wrap>
              <Wrap sx={{ marginBottom: "5rem", width: "100%" }}>
                <ContactForm.Support.Result>
                  {results?.map((item, index: any, arr: any) => {
                    return (
                      <>
                        <Wrap
                          sx={
                            index !== arr.length - 1
                              ? { borderBottom: "1px solid white", marginBottom: "1.5rem", paddingBottom: "10px" }
                              : {}
                          }
                        >
                          {item?.map((key: string) =>
                            <div
                            > {key}
                            </div>)
                          }
                        </Wrap>
                      </>
                    );
                  })}
                  {countryResponse ?? <></>}
                  {countryLoading ? <Loader /> : <></>}
                </ContactForm.Support.Result>
              </Wrap>
            </>
          )}
        </Wrap>
        {/*  */}
        <img src={MaoMarkers} alt="map" style={{ width: "100%", position: "relative" }} />
      </Section>
    </>
  );
};
